<template>
  <div class="luckyStarGameplay">
    <Star-Top></Star-Top>
    <div class="container">
      <div class="title-gameplay">{{$t('menu.xyxwf')}}</div>
      <div class="title-gameplay-desc">{{$t('menu.yourLuckyStarHit')}}</div>
      <!-- <div class="title-gameplay-desc">{{$t('menu.simple')}}</div> -->
      <div class="box-desc">
        <div class="step-title"><span>{{$t('menu.dhxyx')}}</span><i>01</i></div>
        <div class="step-desc">{{$t('menu.dhxyxStep1')}}</div>
      </div>
      <div class="box-desc">
        <div class="step-title"><span>{{$t('menu.waitingLottery')}}</span><i>02</i></div>
        <div class="step-desc">{{$t('menu.dhxyxStep2')}}</div>
      </div>
      <div class="box-desc">
        <div class="step-title"><span>{{$t('menu.checkBonus')}}</span><i>03</i></div>
        <div class="step-desc">{{$t('menu.dhxyxStep3')}}</div>
      </div>
      <div class="title-gameplay">{{$t('menu.winningConditions')}}</div>
      <div class="tip-step">{{$t('menu.winningConditionsDesc')}}</div>
      <div class="step-list">
        <div>{{$t('menu.winningConditionsExam')}}</div>
      </div>
      <div class="step-list">
        <div>{{$t('menu.luckyStarA')}}</div>
        <div>{{$t('menu.luckyStarB')}}</div>
      </div>
      <div class="step-list">
        <div>{{$t('menu.bonusGroupRules')}}</div>
      </div>
      <div class="desc-img"><img src="../../../../../public/images/lukyStar/img-example.png" alt=""></div>
      <div class="title-gameplay">{{$t('menu.bonusFunds')}}</div>
      <div class="simple-desc">{{$t('menu.bonusSource')}}</div>
      <div class="simple-title">{{$t('menu.luckyStarExchange')}}</div>
      <div class="simple-desc">{{$t('menu.injectPrizePool')}}</div>
      <div class="simple-title">{{$t('menu.accumulatedBonus')}}</div>
      <div class="simple-desc">{{$t('menu.reassignDesc')}}</div>
      <!-- <div class="simple-title">{{$t('menu.luckyStarInjection')}}</div>
      <div class="simple-desc">{{$t('menu.totalAverageLuckyStar')}}{{$t('menu.totalAverage')}}<span>XXXXX</span></div> -->
      <div class="simple-img"><img src="../../../../../public/images/lukyStar/tongjitu.png" alt=""></div>
      <div class="simple-example"><span>{{$t('menu.hitCounting')}}</span><span>{{$t('menu.awardPoolAllocation')}}</span></div>
      <div class="simple-example-desc">
        <div class="left"><i class="one"></i><span>{{$t('menu.hitTheTop1')}}</span></div>
        <div class="right">1.8%</div>
      </div>
      <div class="simple-example-desc">
        <div class="left"><i class="two"></i><span>{{$t('menu.hitTheTop2')}}</span></div>
        <div class="right">2.7%</div>
      </div>
      <div class="simple-example-desc">
        <div class="left"><i class="three"></i><span>{{$t('menu.hitTheTop3')}}</span></div>
        <div class="right">4.5%</div>
      </div>
      <div class="simple-example-desc">
        <div class="left"><i class="four"></i><span>{{$t('menu.hitTheTop4')}}</span></div>
        <div class="right">9%</div>
      </div>
      <div class="simple-example-desc">
        <div class="left"><i class="five"></i><span>{{$t('menu.hitTheTop5')}}</span></div>
        <div class="right">18%</div>
      </div>
      <div class="simple-example-desc">
        <div class="left"><i class="six"></i><span>{{$t('menu.hitTheTop6')}}</span></div>
        <div class="right">34%</div>
      </div>
      <div class="simple-example-desc">
        <div class="left"><i class="seven"></i><span>{{$t('menu.destructionPool')}}</span></div>
        <div class="right">30%</div>
      </div>
    </div>
  </div>
</template>

<script>
import StarTop from '../common/StarTop.vue';
import '../styles/LuckyStarGameplay.css'
export default {
  name: 'LuckyStarGameplay',
  components:{StarTop},
}
</script>

<style>

</style>